const calculateToMinute = (timeLeft) => {
  try {
    return timeLeft.hours * 60 + timeLeft.minutes
  } catch (err) {
    return 0
  }
}

const timeDifference = (firstTime, secondTime) => {
  const diff = new Date(firstTime) - new Date(secondTime)
  const days =
    diff / (1000 * 60 * 60 * 24) < 0
      ? Math.ceil(diff / (1000 * 60 * 60 * 24))
      : Math.floor(diff / (1000 * 60 * 60 * 24))
  const hours =
    (diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60) < 0
      ? Math.ceil((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
      : Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
  const minutes =
    (diff % (1000 * 60 * 60)) / (1000 * 60) < 0
      ? Math.ceil((diff % (1000 * 60 * 60)) / (1000 * 60))
      : Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60))
  const seconds =
    (diff % (1000 * 60)) / 1000 < 0
      ? Math.ceil((diff % (1000 * 60)) / 1000)
      : Math.floor((diff % (1000 * 60)) / 1000)

  return {
    days,
    hours,
    minutes,
    seconds,
  }
}

export { calculateToMinute, timeDifference }
